<script>

export default {
	components: {
		Navbar: () => import('@/router/components/Navbar'),
		Footer: () => import('@/router/components/Footer'),
	},
  data() {
    return {

    };
  },
  created() {
    window.addEventListener("scroll", this.windowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  mounted() {

  },
  methods: {
    /**
     * Window scroll method
     */
    windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("topnav-menu-content").classList.toggle("show");
    }
  }
};
</script>

<template>
  <div>
	  <Navbar :isHomepage="false"/>
    <div>
      <section class="section pt-4 mt-5">
        <div class="mt-5 ">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-12 text-left">
                <div>
                  <h4>Imprint according to &sect; 5 TMG</h4>
                  <h5>
                    CFTools™ Cloud is a service provided by:
                  </h5>
                </div>
                <div>
                  <div>
                    CFTools Software GmbH
                  </div>
                  <div>
                    Kiem-Pauli-Stra&szlig;e 8
                  </div>
                  <div>
                    Landshut 84036
                  </div>
                </div>
                <div class="mt-2">
                  <div>
                    Tel. +Germany \ 8 7 1 2 0 5 4 9 0 4 0
                  </div>
                  <div>
                    <b>We do not offer any form of phone support.</b>
                  </div>
                  <div>
                    Fax: +Germany \ 8 7 1 7 8 0 4 9 9 7 3
                  </div>
                  <div>
                    E-Mail:
                    <span style="margin-right: 0.5px;">
                      info
                    </span>
                    <label style="margin-left: 0.38px; margin-right: 0.5px;">
                      @
                    </label>
                    <span style="margin-left: 1.38px;">
                      cftools.cloud
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    Executive Director: Philipp Joos
                  </div>
                  <div>
                    Register court: Amtsgericht Landshut, HRB 12340
                  </div>
                  <div>
                    Tax number: 132/123/30595
                  </div>
                  <div>
                    EU VAT ID § 27a UStG: DE336495434
                  </div>
                  <div>
                    UK VAT ID: 378 0103 08
                  </div>
                  <div>
                    RU IIN: 9909577289
                    <div class="small">
                      You can find more information on the Russian tax rate here:<br>
                      - <a href="https://www.nalog.ru/rn77/" target="_blank">https://www.nalog.ru/rn77/</a><br>
                      - <a href="https://lkioreg.nalog.ru/en/registry" target="_blank">https://lkioreg.nalog.ru/en/registry</a>
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  EU Commission platform for online dispute resolution: <a href="https://www.ec.europa.eu/consumers/odr">https://www.ec.europa.eu/consumers/odr</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Footer start -->
	    <Footer :isHomepage="false"/>
      <!-- Footer end -->
    </div>
  </div>
</template>